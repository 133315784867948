import React, { useEffect } from 'react';
import { Admin, Resource, addRefreshAuthToDataProvider, CustomRoutes, MenuItemLink } from 'react-admin';
import { dataProvider } from './apiDataProvider';
import { BrandList, BrandCreate } from './pages/Brands';
import {UserList, UserShow, UserEdit} from './pages/Users';
import MyLayout from './MyLayout';
import { audiTheme }from './themes';
import MyLoginPage from './MyLoginPage';
import refreshAuth from './refreshAuth';
import { PermissionsList } from './pages/Permissions';
import { RolesList, RoleCreate, RoleEdit } from './pages/Roles';
import { ModuleTypeList, ModuleTypeEdit, ModuleTypeCreate, ModuleTypeShow } from './pages/ModuleType';
import { CarModelList, CarModelEdit, CarModelCreate, CarModelShow } from './pages/CarModels';
import {ChannelList, ChannelEdit, ChannelShow, ChannelCreate } from './pages/Channels';
import PermissionsIcon from './assets/icons/PermissionsIcon';
import BrandsIcon from './assets/icons/BrandsIcon';
import UsersIcon from './assets/icons/UsersIcon';
import {CarTypeEdit, CarTypeCreate, CarTypeShow, CarTypeList } from './pages/CarTypes';
import { DealershipsList, DealershipsShow, DealershipsDelete, DealershipsEdit, DealershipCreate } from './pages/Dealerships';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from './store';
import RolesIcon from './assets/icons/RolesIcon';
import CarModelsIcon from './assets/icons/CarModelsIcon';
import CarTypesIcon from './assets/icons/CarTypesIcon';
import ModuleTypesIcon from './assets/icons/ModuleTypesIcon';
import ChannelsIcon from './assets/icons/ChannelsIcon';
import DealershipGroupsIcon from './assets/icons/DealershipGroupsIcon';
import DealershipsIcon from './assets/icons/DealershipsIcon';
import { DealershipGroupsList } from './pages/DealershipGroups';
import { DealershipGroupsEdit } from './pages/DealershipGroups';
import { DealershipGroupsCreate } from './pages/DealershipGroups';
import { DealershipGroupsDelete } from './pages/DealershipGroups';
import {DealershipGroupsShow} from './pages/DealershipGroups';
import { ErrorProvider } from './contexts/ErrorContext';
import ErrorBoundary from './components/ErrorBoundary';
import NotFound from './components/NotFound/NotFound';
import AssetOverridesIcon  from './assets/icons/AssetOverridesIcon';
import { AssetOverridesList, AssetOverridesShow } from './pages/AssetOverrides';
import DevicesIcon from './assets/icons/DevicesIcon';
import { DeviceList, DeviceEdit, DeviceShow  } from './pages/Devices';
import { FaqList, FaqEdit, FaqShow, FaqCreate } from './pages/Faq';
import FaqsIcon from './assets/icons/FaqsIcon';
import Profile from './components/Profile/Profile';
import { Route } from 'react-router-dom';
import { auth } from './GRP';

const CustomMenu = (props) => {
    const isAdmin=localStorage.getItem('roles')?.includes('NEWRETAIL_ADMIN');
    const isMarketResponsiple=localStorage.getItem('roles')?.includes('ITSP');

    return (
        <div>
             {!isMarketResponsiple && <MenuItemLink
                to="/brands"
                primaryText="Brands"
                leftIcon={<BrandsIcon />}
                onClick={props.onMenuClick}
            />}
            <MenuItemLink
                to="/users"
                primaryText="Users"
                leftIcon={<UsersIcon />}
                onClick={props.onMenuClick}
            />
            {isAdmin && (<>
                <MenuItemLink
                    to="/roles"
                    primaryText="Roles"
                    leftIcon={<RolesIcon />}
                    onClick={props.onMenuClick}
                />
                <MenuItemLink
                    to="/permissions"
                    primaryText="Permissions"
                    leftIcon={<PermissionsIcon />}
                    onClick={props.onMenuClick}
                />
            </>)}
            {!isMarketResponsiple && (<>
                <MenuItemLink
                    to="/carModels"
                    primaryText="Car Models"
                    leftIcon={<CarModelsIcon />}
                    onClick={props.onMenuClick}
                />
                <MenuItemLink
                    to="/carTypes"
                    primaryText="Car Types"
                    leftIcon={<CarTypesIcon />}
                    onClick={props.onMenuClick}
                />
                <MenuItemLink
                    to="/moduleTypes"
                    primaryText="Module Types"
                    leftIcon={<ModuleTypesIcon />}
                    onClick={props.onMenuClick}
                />
            </>)}
            {isAdmin && <MenuItemLink
                to="/channels"
                primaryText="Channels"
                leftIcon={<ChannelsIcon />}
                onClick={props.onMenuClick}
            />}
            <MenuItemLink
                to="/devices"
                primaryText="Devices"
                leftIcon={<DevicesIcon />}
                onClick={props.onMenuClick}
            />
            <MenuItemLink
                to="/dealerships"
                primaryText="Dealerships"
                leftIcon={<DealershipsIcon />}
                onClick={props.onMenuClick}
            />
            <MenuItemLink
                to="/dealershipGroups"
                primaryText="Dealership Groups"
                leftIcon={<DealershipGroupsIcon />}
                onClick={props.onMenuClick}
            />
            <MenuItemLink
                to="/assetOverrides"
                primaryText="Asset Overrides"
                leftIcon={<AssetOverridesIcon />}
                onClick={props.onMenuClick}
            />         
            {!isMarketResponsiple && <MenuItemLink
                to="/faqs"
                primaryText="FAQs"
                leftIcon={<FaqsIcon />}
                onClick={props.onMenuClick}
            />}
             <MenuItemLink
                style={{position:'absolute', bottom:'140px', padding:'0 16px', cursor:'pointer', color:'rgba(0, 0, 0, 0.6)'}}
                to='#'
                primaryText='AVP Portal >'
                onClick={() => {
                    window.open(process.env.REACT_APP_AVP_PORTAL, '_blank');
                }}
            />
            <MenuItemLink
                style={{position:'absolute', bottom:'100px', padding:'0 16px', cursor:'pointer', color:'rgba(0, 0, 0, 0.6)'}}
                to='#'
                primaryText='Imprint >'
                onClick={() => {
                    window.open(process.env.REACT_APP_AVP_IMPRINT, '_blank');
                }}
            /> 
            <MenuItemLink
                style={{position:'absolute', bottom:'60px', padding:'0 16px', cursor:'pointer', color:'rgba(0, 0, 0, 0.6)'}}
                to='#'
                primaryText='Legal >'
                onClick={() => {
                    window.open(process.env.REACT_APP_AVP_LEGAL, '_blank');
                }}
            />     
            <span                 
                style={{
                    position:'absolute',
                    bottom:'6px', 
                    padding:'6px 16px', 
                    color:'rgba(0, 0, 0, 0.6)', 
                    fontSize:'12px'
                }}
            >
            Admin Retail Content Manager {process.env.REACT_APP_VERSION || 'x.x.x'} - Internal System</span>
        </div>
    );
};

const ApiAdmin = () => {
    const isAdmin=localStorage.getItem('roles')?.includes('NEWRETAIL_ADMIN');
    const isMarketResponsiple=localStorage.getItem('roles')?.includes('ITSP');

    const fetchIdTokenPayload = async ()=> {
        const idToken= await auth.getIdTokenPayload();
        idToken && localStorage.setItem('roles', idToken['cognito:groups']);
    }

    useEffect(()=>{
        fetchIdTokenPayload();
    },[])

    return(
        <Provider store = {store}>
            <PersistGate /* loading = {<LoadingPage />}*/ persistor={persistor}>
                <ErrorProvider>
                    <ErrorBoundary>
                        <Admin
                            dataProvider={addRefreshAuthToDataProvider(dataProvider,refreshAuth)}
                            layout={MyLayout}
                            title='Retail Admin'
                            theme={audiTheme}
                            loginPage={MyLoginPage}
                            catchAll={NotFound}
                            menu={CustomMenu}
                        >                 
                            <CustomRoutes>
                                <Route path = '/profile' element = {<Profile/>} />
                            </CustomRoutes>
                            {!isMarketResponsiple && <Resource name = 'brands' list ={BrandList} create = {BrandCreate} />}
                            <Resource name = 'users' list ={UserList} show={UserShow} edit = {UserEdit}/>
                            {isAdmin && (<>
                                <Resource name = 'roles'  list={RolesList} create={RoleCreate} edit= {RoleEdit} />
                                <Resource name = 'permissions'  list ={PermissionsList} />
                                </>)}
                            {!isMarketResponsiple && (<>
                                <Resource name='carModels' list={CarModelList} edit={CarModelEdit} create={CarModelCreate} show={CarModelShow} options={{ label: 'Car Models' }} />
                                <Resource name='carTypes' list = {CarTypeList} edit={CarTypeEdit} create = {CarTypeCreate} show={CarTypeShow}  options = {{label:'Car Types'}} />
                                <Resource name = 'moduleTypes'  list ={ModuleTypeList} edit={ModuleTypeEdit}  create = {ModuleTypeCreate} show={ModuleTypeShow} options={{ label: 'Module Types' }} />
                            </>)}
                            {isAdmin && <Resource name = 'channels' list = {ChannelList} edit = {ChannelEdit} show={ChannelShow} create={ChannelCreate} />}
                            <Resource name = 'devices' list = {DeviceList} edit = {DeviceEdit} show = {DeviceShow} />
                            <Resource name = 'dealerships' list = {DealershipsList} show = {DealershipsShow} delete = {DealershipsDelete} edit={DealershipsEdit} create={DealershipCreate} />
                            <Resource name = 'dealershipGroups' list = {DealershipGroupsList} create = {DealershipGroupsCreate} edit = {DealershipGroupsEdit} delete={DealershipGroupsDelete} show = {DealershipGroupsShow} options = {{label:'Dealership Groups'}} />
                            <Resource name = 'assetOverrides' label = 'Asset Overrides' list = {AssetOverridesList} show = {AssetOverridesShow} options = {{label: 'Asset Overrides'}} />
                            {!isMarketResponsiple && <Resource name = 'faqs' options = {{label:'FAQs'}} list = {FaqList} edit = {FaqEdit} show = {FaqShow} create = {FaqCreate}/>}
                        </Admin>
                    </ErrorBoundary>
                </ErrorProvider>
            </PersistGate>
        </Provider>
)};

export default ApiAdmin;